<template>
	<v-dialog :width="360" v-model="dialog">
		<template v-slot:activator="{on}">
			<v-btn :text="text" :small="small" :block="block" v-on="on" color="red" dark>{{buttonText}}</v-btn>	
		</template>
		<v-card>
			<v-card-title></v-card-title>
			<v-card-text>
				{{$t("misc.are_you_sure")}}
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="deleteObject" color="red" dark>{{$t("buttons.delete")}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	export default {
		name: "Delete", 
		props: {
			id: { type: String }, // @doc the ID of the object 
			storeModule: { type: String }, // the corresponding vuex module
			text: {type: Boolean, default: true }, 
			small: {type: Boolean, default: true },
			block: {type: Boolean },
			buttonText: {type: String, default: "Delete" }
		},
		data: () => {
			return {
				deleted: false,
				dialog: false
			}
		},
		methods: {
			deleteObject(){
				const self = this;
				self.$store.dispatch("deleteDoc", {id: self.id, storeModule: self.storeModule}).then(() => {
					self.dialog = false;
					self.$emit("close")
				})
			}
		}

	}
// </script>"