<template>
	<v-sheet color="transparent" class="pa-4">
		<!-- <p class="text-right">
			<Import :storeModule="storeModule" :defaultFields="defaultFields"></Import>
		</p> -->
		<!-- <SearchByTags
			v-if="searchByTags"
			@search="(data) => (search = data)"
			:searchItems="documents"
			@change="(data) => (filtered = data)"
		></SearchByTags>
		<SearchAndFilter
			v-if="searchAndFilter"
			:items="items"
			@search="(data) => (search = data)"
			@filtered="(f) => (filtered = f)"
			@cleared="filtered = false"
			:fieldOptions="filterOptions"
		></SearchAndFilter> -->
		<v-text-field
			v-if="!searchByTags && !searchAndFilter"
			v-model="search"
			:label="$t('misc.search')"
		></v-text-field>
		<v-card flat color="secondary">
			<v-data-table
				:headers="headers"
				:search="search"
				:items="items"
				class="mw-database-list"
			>
				<!-- if the field has a named slot -->
				<template v-for="slot in customSlots" v-slot:[slot]="{ item }">
					<span :key="slot"> <slot :name="slot" v-bind="item"></slot></span>
				</template>
				<!--  -->
				<template v-slot:item.button="{ item }">
					<span :id="item.id"></span>
                    <list-buttons
						:id="item.id"
						:storeModule="storeModule"
						:path="path"
						@edit="goTo(item.id)"
					></list-buttons>
				</template>
			</v-data-table>
		</v-card>
		<!-- <Export :rows="items"></Export> -->

		<p class="text-right" v-if="newBtn">
			<v-btn color="primary" :to="`/${editPath}/new`">{{
				$t(`buttons.new`)
			}}</v-btn>
		</p>
	</v-sheet>
</template>

<style lang="less">
.mw-database-list td:last-child {
	padding-right: 0px !important;
	text-align: right !important;
}
</style>

<script type="text/javascript">
import ListButtons from "@/components/buttons/ListButtons";
// import Import from "@/components/downloads/Import";
// import Export from "@/components/downloads/Export";
// import SearchByTags from "@/components/panels/SearchByTags";
// import SearchAndFilter from "@/components/panels/SearchAndFilter";

export default {
	name: "MwList",
	props: {
		storeModule: { type: String }, // @doc - the corresponding data module in vuex
		columns: { type: Array }, // @doc the headers for the table - an array of {key: String, value: String, default: Boolean}. Default true for fields which are included in the import
		searchByTags: { type: Boolean, default: false }, //@doc replaces the search field with a search by tags element
		searchAndFilter: { type: Boolean, default: false }, //@doc replaces the search field with a search by filters element
		filterOptions: { type: Array }, //@doc fields to filter by if the searchAndFilterOption is in use
		path: { type: String }, // @doc the path of the linked object - almost always the same as storeModule
		newBtn: {type: Boolean, default: true }, // @doc hides the new section button 
		filtered: {type: Array } // A list of IDs to include
	},
	data: () => {
		return {
			search: "",
		};
	},
	components: {
		ListButtons,
		// SearchByTags,
		// SearchAndFilter,
		// Import,
		// Export,
	},
	computed: {
		editPath(){
			return this.path || this.storeModule
		},
		defaultFields() {
			return this.headers
				.filter((col) => col.default)
				.map((col) => col.value)
				.join(",");
		},
		documents() {
			let docs = this.$store.state[this.storeModule].data;
			return docs;
		},
		headers() {
			var headers = this.columns;
			headers.push({ value: "button", text: " ", width: "35%" });
			return headers;
		},
		items() {
			if (!this.filtered) {
				return Object.values(this.documents);
			} else {
				return this.filtered.map((id) => this.documents[id]);
			}
		},
		customSlots() {
			return Object.keys(this.$scopedSlots);
		},
	},
	methods: {
		goTo(id){
			this.$router.push(`${this.editPath}/${id}`)
		}	
	},
	created() {
		this.$store.dispatch(`${this.storeModule}/openDBChannel`);
	},
	destroyed() {
		this.$store.dispatch(`${this.storeModule}/closeDBChannel`);
	},
};
//
</script>
"
