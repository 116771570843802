<template>
	<span>	
		<v-btn small text color="primary" v-bind="$props" @click="$emit('edit', id )">View</v-btn>
		<copy-button v-if="copyBtn" :id="id" :storeModule="storeModule"></copy-button>
		<delete-button  v-if="deleteBtn" :id="id" :storeModule="storeModule"></delete-button>
	</span>	
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";

	import DeleteButton from "@/components/buttons/DeleteButton";
	import CopyButton from "@/components/buttons/CopyButton";

	export default {
		name: "ListButtons", 
		props: {
			id: { type: String }, // @doc the ID of the object 
			storeModule: { type: String }, // the corresponding vuex module
			path: {  type: String  }, // the path for the url - almost always the same as the storeModule
			copyBtn: {type: Boolean, default: true },
			deleteBtn: {type: Boolean, default: true }
		},
		components: {
			DeleteButton, 
			CopyButton,
		},

	}
// </script>"